import { useQuery } from "react-query";
import queryClient from "../constants/queryClient";
import request from "../utilities/request";
import { semrushURLs } from "../constants/apiURLs";

export const queryKey = "SEMRUSH_STATUS";

export const useSemrushAPIStatus = () => {
  const { data, ...meta } = useQuery(queryKey, () => {
    return request().get(semrushURLs.status());
  });

  const isError = data?.isError ?? false;
  if (isError) {
    return {
      ...meta,
      data: {},
      isError: true,
      error: data,
    };
  }

  return {
    data: data ?? {},
    ...meta,
  };
};

export const invalidateSemrushAPIStatus = () => {
  queryClient.invalidateQueries(queryKey);
};
