import { getEnvironment, ENV_LOCAL } from "../utilities/environment";

export let baseURL = "https://vortex-mini.auxinsights.com/api";

export const determineBaseURL = () => {
    let url = baseURL;

    // Check the current environment to define the API URL
    switch (getEnvironment()) {
        case ENV_LOCAL:
            url = "http://127.0.0.1:8080/api";
            break;
        default:
            url = "https://vortex-mini-api.auxinsights.com/api";
    }


    return url;
};

baseURL = determineBaseURL();

export const authURLs = {
    googleTokenExchange: () => {
        return `${baseURL}/oauth/google/exchange`;
    },
}

export const clientURLs = {
    list: () => {
        return `${baseURL}/clients`;
    },
    create: () => {
        return `${baseURL}/clients`;
    },
    get: (clientId) => {
        return `${baseURL}/clients/${clientId}`;
    },
    update: (clientId) => {
        return `${baseURL}/clients/${clientId}`;
    },
    delete: (clientId) => {
        return `${baseURL}/clients/${clientId}`;
    },
    project: (clientID, projectID) => {
        return `${baseURL}/clients/${clientID}/projects/${projectID}`;
    },
    createProject: (clientID) => {
        return `${baseURL}/clients/${clientID}/projects`;
    },
    projectReprocess: (clientID, projectID) => {
        return `${baseURL}/clients/${clientID}/projects/${projectID}/reprocess`;
    },
    projectProcessGoogleAnalytics: (clientID, projectID) => {
        return `${baseURL}/clients/${clientID}/projects/${projectID}/process_google_analytics`;
    },
    projectEventSubscribe: (clientID, projectID) => {
        return `${baseURL}/clients/${clientID}/projects/${projectID}/subscribe`;
    },
    projectData: (clientID, projectID) => {
        return `${baseURL}/clients/${clientID}/projects/${projectID}/data`;
    },
    projectDataReload: (clientID, projectID) => {
        return `${baseURL}/clients/${clientID}/projects/${projectID}/data/reload`;
    },
    projectInputs: (clientID, projectID) => {
        return `${baseURL}/clients/${clientID}/projects/${projectID}/input`;
    }
};

export const similarwebURLs = {
    status: () => {
        return `${baseURL}/similarweb`;
    }
}

export const semrushURLs = {
    status: () => {
        return `${baseURL}/semrush`;
    }
}
