import { useQuery } from "react-query";
import queryClient from "../constants/queryClient";
import request from "../utilities/request";
import { similarwebURLs } from "../constants/apiURLs";

export const queryKey = "SIMILARWEB_STATUS";

export const useSimilarwebAPIStatus = () => {
  const { data, ...meta } = useQuery(queryKey, () => {
    return request().get(similarwebURLs.status());
  });

  const isError = data?.isError ?? false;
  if (isError) {
    return {
      ...meta,
      data: {},
      isError: true,
      error: data,
    };
  }

  return {
    data: data ?? {},
    ...meta,
  };
};

export const invalidateSimilarwebAPIStatus = () => {
  queryClient.invalidateQueries(queryKey);
};
