import { invalidateSemrushAPIStatus } from "./fetchSemrushStatus";

// Run the timer only once
let hasStarted = false;
if (!hasStarted) {
  hasStarted = true;
  console.debug("Starting Semrush API status refresher (runs every 20 mins)");
  setInterval(() => {
    invalidateSemrushAPIStatus();
  }, 30 * 60 * 1000);
}
export const SemrushStatusRefresher = () => {
  return null;
};
