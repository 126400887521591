import { apiCall } from "./request";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { authURLs } from "../constants/apiURLs";
import { jwtDecode } from "jwt-decode";
import propTypes from "prop-types";
import { useAuth } from "./useAuth";
import { AuthContext } from "./authContext";

export const API_TOKEN_KEY = "api_token";

function AuthProvider({ children }) {
  // Check the cookie for a user
  let cookieProfile = null;
  let cookieToken = "";
  try {
    cookieProfile = JSON.parse(
      window.localStorage.getItem("google_profile") ?? null
    );
    cookieToken = window.localStorage.getItem("api_token");
  } catch (e) {
    console.warn("Clearing invalid localstorage items");
    window.localStorage.removeItem("google_profile");
    window.localStorage.removeItem("api_token");
  }

  const isAlreadyAuthenticated = loginValid(cookieProfile, cookieToken);

  let [profile, setProfile] = React.useState(cookieProfile);
  let [token, setToken] = React.useState(cookieToken);
  let [isAuthenticated, setIsAuthenticated] = React.useState(
    isAlreadyAuthenticated
  );

  let signin = (googleAccountToken, success, failure) => {
    console.info("Attemping to sign in...");

    // @todo this should be the login function from OAuth test

    // perform the sign in post request
    apiCall
      .post(authURLs.googleTokenExchange(), {
        token: googleAccountToken,
      })
      .then((response) => {
        console.info("Sign in response: ", response);
        const newProfile = response.data.profile;
        const token = response.data.token;

        setProfile(newProfile);
        setToken(token);
        setIsAuthenticated(true);
        window.localStorage.setItem(
          "google_profile",
          JSON.stringify(newProfile)
        );
        window.localStorage.setItem("api_token", token);

        // If callback is set, run it
        if (success) {
          success({
            profile: newProfile,
            token: token,
          });
        }
      })
      .catch((error) => {
        console.error("Sign in error: ");
        console.error(error);

        if (failure) {
          failure(error);
        }
      });
  };

  let signout = (callback) => {
    console.info("Attemping to sign out...");

    // Clear the cookies
    window.localStorage.removeItem("google_profile");
    window.localStorage.removeItem("api_token");

    // Clear the state
    setProfile(null);
    setToken(null);
    setIsAuthenticated(false);

    // If callback is set, run it
    if (callback) {
      callback();
    }
  };

  let value = {
    profile,
    signin,
    signout,
    isAuthenticated,
    token,
  };

  return (
    <AuthContext.Provider value={value}> {children} </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: propTypes.node,
};

function RequireAuth({ children }) {
  let { token, isAuthenticated } = useAuth();
  let location = useLocation();

  if (tokenExpired(token)) {
    return (
      <Navigate
        to={{ pathname: "/login", search: "?exp", state: { from: location } }}
        replace
      />
    );
  }

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    console.debug("Redirecting to login page");

    // Encode the current URL as the "from" parameter to add to the URL
    // when we redirect to the login page
    let from = encodeURIComponent(location.pathname + location.search);
    const loginURL = `/login?from=${from}`;

    return <Navigate to={loginURL} state={{ from: location }} replace />;
  }

  return children;
}

RequireAuth.propTypes = {
  children: propTypes.node,
};

export { AuthProvider, RequireAuth };

/*

        HELPERS

*/

// Helper to validate that a JWT token and user are set in the cookie
function loginValid(user, token) {
  return user && token && tokenValid(token);
}

function tokenExpired(token) {
  // Token should be 3 sections separated by a period
  if (!(token && token !== null && token.split(".").length === 3)) {
    return false;
  }

  let claims = null;
  try {
    claims = jwtDecode(token);
  } catch (e) {
    return false;
  }

  // Check if the token is expired
  return claims.exp < Math.floor(Date.now() / 1000);
}

function tokenValid(token) {
  // Token should be 3 sections separated by a period
  if (!(token && token !== null && token.split(".").length === 3)) {
    return false;
  }

  // Token should decode OK
  try {
    jwtDecode(token);
  } catch (e) {
    return false;
  }

  if (tokenExpired(token)) {
    return false;
  }

  return true;
}
