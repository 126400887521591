import { invalidateSimilarwebAPIStatus } from "./fetchSimilarwebStatus";

// Run the timer only once
let hasStarted = false;
if (!hasStarted) {
  hasStarted = true;
  console.debug("Starting Similarweb API status refresher");
  setInterval(() => {
    invalidateSimilarwebAPIStatus();
  }, 30000);
}
export const SimilarwebStatusRefresher = () => {
  return null;
};
