import axios from "axios";
import { API_TOKEN_KEY } from "./auth";

export const requestHeaders = () => {
  return {};
};

// @docs https://shopify.dev/apps/auth/oauth/session-tokens/axios
export const apiCall = axios.create();
// Intercept all requests on this Axios instance
apiCall.interceptors.request.use(function (config) {
  config.headers["Accept"] = "application/json";
  config.headers["Content-Type"] = "application/json";
  config.headers["X-Vortex-Mini-Nonce"] = Math.random()
    .toString(36)
    .substring(2);

  // Add auth token to every request if we have it
  const token = window.localStorage.getItem(API_TOKEN_KEY);
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

apiCall.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.groupCollapsed("API Error");
    console.error(error);
    console.error(error?.response?.data);

    // What URL was requested?
    const url = error?.config?.url;
    console.error(url);

    console.groupEnd();

    // If it's a network error, redirect to that page
    if (error.code === "ERR_NETWORK") {
      // Only redirect if we're not already on the network error page (path includes /error/network)
      if (window.location.pathname.includes("/error/network")) {
        return Promise.reject(error);
      }

      // @todo send an error report here
      window.location.href = "/error/network";
    }

    // If error is 401 & we're not already on the login page, then  redirect to login page
    if (
      error.response.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      window.location.assign("/login?exp");
    }

    return Promise.reject(error);
  }
);

const request = () => {
  const headers = {};

  return {
    get: (url, options) =>
      apiCall
        .get(url, {
          headers: { ...headers },
          ...options,
        })
        .then((response) => response.data)
        .catch((error) => {
          return {
            ...error,
            isError: true,
          };
        }),
    post: (url, payload, options, throwError = false) =>
      apiCall
        .post(url, payload, {
          headers: { ...headers },
          ...options,
        })
        .then((response) => response.data)
        .catch((error) => {
          if (throwError) {
            const message =
              error?.response?.data?.error || error?.response?.message;
            throw Error(message);
          }

          return {
            ...error,
            isError: true,
          };
        }),
    put: (url, payload, options) =>
      apiCall
        .put(url, payload, {
          headers: { ...headers },
          ...options,
        })
        .then((response) => response.data)
        .catch((error) => {
          return {
            ...error,
            isError: true,
          };
        }),
    delete: (url, payload, options) =>
      apiCall
        .delete(url, payload, {
          headers: { ...headers },
          ...options,
        })
        .then((response) => response.data)
        .catch((error) => {
          return {
            ...error,
            isError: true,
          };
        }),
  };
};

export default request;
