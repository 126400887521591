import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import queryClient from "./constants/queryClient";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { RouteList } from "./RouteList";
import { SimilarwebStatusRefresher } from "./api/fetchSimilarwebStatusRefresher";
import { SemrushStatusRefresher } from "./api/fetchSemrushStatusRefresher";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider } from "./utilities/auth";
import AppErrorBoundary from "./pages/AppBoundaryPage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#16181F",
    },
    secondary: {
      main: "#16181F",
    },
    background: {
      default: "#F2F2F2",
    },
    success: {
      main: "#388E3C",
    },
    error: {
      main: "#D32F2F",
    },
    warning: {
      main: "#FFA000",
    },
    info: {
      main: "#033FFF",
    },
  },
  typography: {
    fontFamily: "Inconsolata, Arial, sans-serif",
    h1: {
      fontFamily: "Montserrat, Arial, sans-serif",
    },
    h2: {
      fontFamily: "Montserrat, Arial, sans-serif",
    },
    h3: {
      fontFamily: "Montserrat, Arial, sans-serif",
    },
    h4: {
      fontFamily: "Montserrat, Arial, sans-serif",
    },
    h5: {
      fontFamily: "Montserrat, Arial, sans-serif",
    },
    h6: {
      fontFamily: "Montserrat, Arial, sans-serif",
    },
  },
});

const pubnub = new PubNub({
  publishKey: "myPublishKey",
  subscribeKey: import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY,
  uuid:
    import.meta.env.VITE_PUBNUB_UUID || "a68f3cca-8ee4-11ee-b9d1-0242ac120002",
});

function App() {
  return (
    <AppErrorBoundary>
      <GoogleOAuthProvider
        clientId={import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID}
      >
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <SimilarwebStatusRefresher />
            <SemrushStatusRefresher />
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                <PubNubProvider client={pubnub}>
                  <BrowserRouter basename="/">
                    <CssBaseline />
                    <RouteList />
                  </BrowserRouter>
                </PubNubProvider>
              </SnackbarProvider>
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </AuthProvider>
      </GoogleOAuthProvider>
    </AppErrorBoundary>
  );
}

export default App;
