// This is a function that tries to detect the error message from an API response and match it to a human readable error
// message. It's not perfect, but it's better than nothing.
//
export const getErrorMessage = (error) => {
  // find the error message. If it's a string, great, otherwise, check error.error and error.message
  let errorMessage = error;

  // If not a string, check error.data.error and error.data.message
  if (typeof error !== "string") {
    errorMessage =
      errorMessage.data?.error ?? errorMessage.data?.message ?? error;
  }
  if (typeof errorMessage !== "string") {
    errorMessage =
      errorMessage?.response.data?.error ??
      errorMessage?.response?.data?.message ??
      errorMessage;
  }

  // Maybe we already filtered to the data level
  if (typeof errorMessage !== "string") {
    errorMessage = error.error ?? error.message ?? errorMessage;
  }

  // If it's still not a string, return it as JSON
  if (typeof errorMessage !== "string") {
    return JSON.stringify(errorMessage);
  }

  // Try to match the error message to a human readable error message
  if (errorMessage.includes("project has a job running")) {
    return "The job is running, you cannot run another job until it is finished.";
  }
  if (
    errorMessage.includes("email domain") &&
    errorMessage.includes("not allowed")
  ) {
    return "Your email is not authorized to access this site.";
  }

  return errorMessage;
};
