export const ENV_PRODUCTION = "production";
export const ENV_LOCAL = "localhost";

export const getEnvironment = () => {
    // Check if URL contains localhost or 127.0.0.1
    if (
        window.location.hostname.includes("localhost") ||
        window.location.hostname.includes("127.0.0.1") ||
        window.location.hostname.includes("m1t.local")
    ) {
        return ENV_LOCAL;
    }

    return ENV_PRODUCTION;

};