import React from "react";
import { Routes, Route } from "react-router-dom";
import OAuthTest from "./pages/OAuthTest";
import { RequireAuth } from "./utilities/auth";

// Lazy load pages
const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage"));
const NetworkErrorPage = React.lazy(() => import("./pages/NetworkErrorPage"));

const ClientsPage = React.lazy(() => import("./pages/clients/ClientsListPage"));
const ClientPage = React.lazy(() =>
  import("./pages/clients/client/ClientPage")
);
const ProjectPage = React.lazy(() =>
  import("./pages/clients/client/project/ProjectPage")
);
const LoginPage = React.lazy(() => import("./pages/Login"));

export const RouteList = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <ClientsPage />
          </RequireAuth>
        }
      />

      {/* Protected Routes */}
      <Route
        path="/clients"
        element={
          <RequireAuth>
            <ClientsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/clients/:clientID"
        element={
          <RequireAuth>
            <ClientPage />
          </RequireAuth>
        }
      />
      <Route
        path="/clients/:clientID/projects/:projectID"
        element={
          <RequireAuth>
            <ProjectPage />
          </RequireAuth>
        }
      />

      <Route path="/oauth" element={<OAuthTest />} />

      {/* Common, unprotected routes */}
      <Route path="/error/network" element={<NetworkErrorPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
