import { Component } from "react";
import { Container, Paper, Typography } from "@mui/material";
import PropTypes from "prop-types";

class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({ error: error, errorInfo: errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container style={{ marginTop: "100px" }}>
          <Paper elevation={3} style={{ padding: "20px" }}>
            <Typography variant="h5" color="error">
              Something went wrong.
            </Typography>
            <Typography variant="body1">
              {this.state.error && this.state.error.toString()}
            </Typography>
            <Typography variant="body2">
              {this.state.errorInfo && this.state.errorInfo.componentStack}
            </Typography>
          </Paper>
        </Container>
      );
    }

    return this.props.children;
  }
}

AppErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppErrorBoundary;
