import { Avatar } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../api/errors";
import { useAuth } from "../utilities/useAuth";

const OAuthTest = () => {
  const { profile, signin, signout, isAuthenticated } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      signin(
        codeResponse.access_token,
        (response) => {
          console.info("Sign in success: ", response);
        },
        (error) => {
          console.error("Sign in error: ", error);
          enqueueSnackbar(getErrorMessage(error), { variant: "error" });
        }
      );
    },
    onError: (error) => {
      console.error("Google Login Failed:", error);
      enqueueSnackbar("Google Login Failed", { variant: "error" });
    },
  });

  return (
    <div>
      <h2>React Google Login</h2>
      <br />
      <br />
      {profile ? (
        <div>
          <Avatar
            src={profile.picture}
            alt="user image"
            sx={{
              width: 100,
              height: 100,
            }}
          />
          <h3>User Logged in</h3>
          <p>Name: {profile.name}</p>
          <p>Email Address: {profile.email}</p>
          <p>Authed: {isAuthenticated ? "Yes" : "No"}</p>
          <br />
          <button onClick={signout}>Log out</button>
          <br />
        </div>
      ) : (
        <button onClick={() => login()}>Sign in with Google 🚀 </button>
      )}
      <br />
      <br />
      API Token: {window.localStorage.getItem("api_token")}
      <br />
      Google Profile:
      {JSON.stringify(window.localStorage.getItem("google_profile"))}
      <br />
      <br />
    </div>
  );
};
export default OAuthTest;
